import React from 'react';

import './mvp-five-part.scss';
import BottomDevPart from '../../pages/devPage/bottomDevPart';
import ManInHatV2 from '../../components/manInHatV2/manInHatV2';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import CustomForm from '../../pages/devPage/customForm';
const MvpFivePart = () => {
   const postUrl = `https://kulaplus.us12.list-manage.com/subscribe/post?u=c6496d48a58fcb2a4e1552057&id=859bb6828e`;
   const [_, setDisableInput] = React.useState(false);
   return (
      <section id='scroll-id4'>
         <div className='flex flex-col gap-5 justify-center'>
            <div className='flex items-center justify-center  mt-[50px] max-sm:mt-[50px] max-sm:flex-col-reverse'>
               <div className='w-full flex justify-center gap-5'>
                  <div className='flex flex-col  h-full  text-left relative left-[200px] max-sm:left-[0px] max-sm:w-[90%] max-lg:ml-[-150px] max-sm:ml-[0]'>
                     {/* <KulaImg /> */}
                     <div className='flex  relative left-[0px] top-[0]'>
                        <h1 className='mvp-five-part__text flex justify-left items-left mt-[20px] '>
                           Kula App
                        </h1>{' '}
                     </div>
                     <h2 className='mvp-five-part__text-one flex justify-left items-left mt-[30px] '>
                        We’ll do our best <br /> to deliver the app in 2023
                     </h2>{' '}
                     <h3 className='mvp-five-part__text-two mt-[20px]'>
                        to keep up with all the kul updates, <br />
                        leave you email here
                     </h3>
                     <div className='mvp-five-part__input mt-[20px]'>
                        <MailchimpSubscribe
                           url={postUrl}
                           render={({ subscribe, status, message }) => (
                              <CustomForm
                                 status={status}
                                 message={message}
                                 onValidated={(formData) => subscribe(formData)}
                                 setDisableInput={setDisableInput}
                              />
                           )}
                        />
                     </div>
                  </div>
               </div>
               <div className='w-full ml-[150px] mt-[10px] max-sm:ml-[50px] max-sm:w-[80%]'>
                  <img
                     src='/image/kula-mvp/five-part-first-img.svg'
                     alt=''
                  ></img>
               </div>
            </div>
            <div className='flex mt-[100px] items-center  justify-center m-auto '>
               <div className='mvp-five-part__man-part flex w-full max-sm:flex-col'>
                  <div className='mvp-five-part__man-img w-[50%] pr-5 '>
                     {' '}
                     <ManInHatV2 />
                  </div>
                  <div className='w-[50%] flex flex-col  text-left pl-[80px] max-sm:w-[100%] max-sm:pl-[0px]'>
                     <h2 className='mvp-five-part__text-near-man mt-[100px] max-sm:mt-[-80px]'>
                        have You ever thought
                        <br /> of inviting a stranger <br /> to an event?..{' '}
                     </h2>
                     <h3 className='mvp-five-part__text-near-man-two mt-[70px]'>
                        ...no worries. We have
                        <br /> and made it Kula{' '}
                     </h3>
                  </div>
               </div>
            </div>
            <div className='mvp-five-part__bottom-part w-[80%] m-auto'>
               <BottomDevPart />
            </div>
         </div>
      </section>
   );
};

export default MvpFivePart;
