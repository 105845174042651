import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import './firstPartMainPage.scss';

import SignUpButton from '../buttons/signUpButton/signUpButton';

import BlobOne from '../blobs/blob-one';
import BlobTwo from '../blobs/blob-two';
import BlobThree from '../blobs/blob-three';

const FirstPartMainPage = ({ handleScrollUsers }) => {
   const [currentButton, setCurrentButton] = React.useState('');

   React.useEffect(() => {
      handleScrollUsers(currentButton);
   }, [currentButton]);

   ///Enable mobile layout
   const [mobileLayout, setMobileLayout] = React.useState(false);
   ///GET CURRENT WINDOW WIDTH
   const [windowSize, setWindowSize] = React.useState(getWindowSize());
   React.useEffect(() => {
      function handleWindowResize() {
         setWindowSize(getWindowSize());
      }
      if (windowSize.innerWidth <= 750) {
         setMobileLayout(true);
      } else {
         setMobileLayout(false);
      }

      window.addEventListener('resize', handleWindowResize);
      return () => {
         window.removeEventListener('resize', handleWindowResize);
      };
   }, [windowSize]);
   function getWindowSize() {
      const { innerWidth, innerHeight } = window;

      return { innerWidth, innerHeight };
   }

   const controls = useAnimation();
   const [ref, inView] = useInView();
   useEffect(() => {
      if (inView) {
         controls.start('visible');
      }
   }, [controls, inView]);

   // const MotionBox = chakra(motion.div)

   const animationFunc = (x, y, duration, delay) => {
      //old animation left/right text appear
      const textAnimation = {
         hidden: {
            x: x,
            y: y,
            opacity: 0
         },
         visible: (custom) => ({
            x: 0,
            y: 0,
            opacity: 1,
            transition: { duration: 0.5, delay: 0.2 * delay } //delay
         })
      };
      return textAnimation;
   };

   const blobAnimation = (duration, delay) => {
      const blobAnimation = {
         hidden: {
            width: 0
         },
         visible: {
            width: '100%',
            transition: { duration: duration, delay: 0.2 * delay }
         }
      };
      return blobAnimation;
   };

   //Animation for first text

   const AnimatedTextLetters = ({ text, delay, align = false }) => {
      const letters = Array.from(text);
      const container = {
         hidden: { opacity: 0 },
         visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.01, delayChildren: delay * 1 }
         })
      };
      const child = {
         visible: {
            opacity: 1,
            y: 0,
            transition: {
               type: 'spring',
               damping: 12,
               stiffness: 100
            }
         },
         hidden: {
            opacity: 0,
            y: 100,
            transition: {
               type: 'spring',
               damping: 12,
               stiffness: 100
            }
         }
      };
      return (
         <motion.div
            style={
               !align
                  ? {
                       oveflow: 'hidden',
                       display: 'flex',
                       alignItems: 'left',
                       justifyContent: 'left'
                    }
                  : {
                       oveflow: 'hidden',
                       display: 'flex',
                       alignItems: 'right',
                       justifyContent: 'right'
                    }
            }
            variants={container}
            initial='hidden'
            animate='visible'
            viewport={{ once: true }}
            className='animated_text_letters'
         >
            {letters.map((letter, index) => (
               <motion.p variants={child} key={index}>
                  {letter === ' ' ? '\u00A0' : letter}
               </motion.p>
            ))}
         </motion.div>
      );
   };

   return (
      <section id='button-header1'>
         <div className='container-main-page'>
            <motion.div
               className='first-page__main-container'
               initial='hidden'
               whileInView='visible'
               // viewport={{ amount: 0.2, once: true }} //delay after scroll
               viewport={{ once: true }}
            >
               <AnimatedTextLetters
                  text={'have You ever thought'}
                  delay={0.1}
               ></AnimatedTextLetters>
               <AnimatedTextLetters
                  text={'of inviting a stranger'}
                  delay={0.2}
               ></AnimatedTextLetters>
               <AnimatedTextLetters
                  text={'to an event?..'}
                  delay={0.3}
               ></AnimatedTextLetters>
               {/* <div className='first-page__first-text'>
                  {mobileLayout ? (
                     <motion.p
                        custom={2}
                        variants={animationFunc(-1000, 0, 0.3, 0)}
                        className='text-mainpage'
                     >
                        <br /> thought of inviting <br />a stranger to an
                        <br />
                        event?..
                     </motion.p>
                  ) : (
                     <>
                        <motion.p
                           custom={2}
                           variants={animationFunc(0, 50, 0.3, 0)}
                           className='text-mainpage'
                        >
                           have You ever thought{' '}
                        </motion.p>

                        <motion.p
                           custom={2}
                           variants={animationFunc(0, 50, 0.3, 1.0)}
                           className='text-mainpage'
                        >
                           of inviting a stranger{' '}
                        </motion.p>

                        <motion.p
                           custom={2}
                           variants={animationFunc(0, 50, 0.3, 2.0)}
                           className='text-mainpage'
                        >
                           to an event?..{' '}
                        </motion.p>
                     </>
                  )}
               </div> */}
               <div className='first-page__first-image'>
                  <BlobOne blobAnimation={blobAnimation} />
                  {/* <motion.svg
                     // width='auto'
                     //  height='175'
                     variants={animationFunc(150, 400, 0.5, 0.4)}
                     viewBox='0 0 187 175'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                     className='first-page__yellow-image'
                  >
                     <g>
                        <path
                           fillRule='evenodd'
                           clipRule='evenodd'
                           d='M152.316 147.103C141.595 158.107 127.769 166.553 112.651 169.145C97.9977 171.657 91.4506 160.889 76.6164 160.082C51.7083 158.726 22.668 185.801 4.13322 169.145C-12.6785 154.038 28.5271 127.626 34.6514 105.799C39.194 89.6084 14.2238 64.8644 21.6514 49.772C31.4111 29.9408 43.1365 8.65183 63.9604 1.3061C84.5796 -5.96744 101.747 19.7265 121.693 28.5626C139.143 36.2925 161.265 35.0135 173.365 49.772C185.747 64.8729 189.161 86.6606 185.022 105.799C181.219 123.384 164.878 134.21 152.316 147.103Z'
                           fill='#FFB40A'
                        />
                     </g>
                  </motion.svg> */}
                  <BlobTwo blobAnimation={blobAnimation} />
                  {/* <motion.svg
                     width='100%'
                     viewBox='0 0 139 104'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                     className='first-page__orange-image'
                     variants={animationFunc(150, 400, 0.5, 0.9)}
                  >
                     <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M81.744 0C114.256 0 139 28.3165 139 61.2833C139 89.4316 109.504 104 81.744 104C45.831 104 0 97.6984 0 61.2833C0 21.0274 42.0431 0 81.744 0Z'
                        fill='#FF5E27'
                     />
                  </motion.svg> */}
               </div>
            </motion.div>

            <motion.div
               className='first-page__second-part'
               initial='hidden'
               whileInView='visible'
               viewport={{ amount: 0.2, once: true }} //delay after scroll
            >
               <div className='first-page__left-image'>
                  <BlobThree blobAnimation={blobAnimation} />
                  {/* <motion.svg
                     width='95'
                     height='98'
                     viewBox='0 0 95 98'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                     variants={animationFunc(-100, 270, 0.5, 1.3)}
                  >
                     <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M23.8547 13.8397C41.2972 -4.24032 69.3999 -3.69515 86.6629 12.9592C101.403 27.1792 93.2073 50.9415 78.3143 66.3789C59.0473 86.35 31.1597 108.653 12.091 90.2567C-8.98903 69.9201 2.55563 35.9173 23.8547 13.8397Z'
                        fill='#1E56E1'
                     />
                  </motion.svg> */}
               </div>

               <div className='first-page__right-text'>
                  {window.innerWidth < 750 ? (
                     <>
                        <AnimatedTextLetters
                           text={'…no worries.'}
                           delay={1.2}
                           align={true}
                        ></AnimatedTextLetters>
                        <AnimatedTextLetters
                           text={'We have.'}
                           delay={1.2}
                           align={true}
                        ></AnimatedTextLetters>
                        <AnimatedTextLetters
                           text={'and made it Kula'}
                           delay={1.3}
                           align={true}
                        ></AnimatedTextLetters>
                     </>
                  ) : (
                     <>
                        <AnimatedTextLetters
                           text={'…no worries. We have.'}
                           delay={1.2}
                        ></AnimatedTextLetters>
                        <AnimatedTextLetters
                           text={'and made it Kula'}
                           delay={1.3}
                        ></AnimatedTextLetters>
                     </>
                  )}

                  {/* {!mobileLayout ? (
                     <>
                        <motion.p
                           custom={2}
                           variants={animationFunc(0, 50, 0.3, 3.0)}
                        >
                           …no worries. We have.{' '}
                        </motion.p>
                        <motion.p
                           custom={2}
                           variants={animationFunc(0, 50, 0.3, 4.0)}
                        >
                           and made it Kula
                        </motion.p>
                     </>
                  ) : (
                     <>
                        <motion.span>
                           …no worries.
                           <br /> We did. <br />
                        </motion.span>

                        <span>And made it Kula</span>
                     </>
                  )} */}
               </div>
            </motion.div>

            <div className='first-page__third-part'>
               <motion.div
                  className='first-page__central-text'
                  initial='hidden'
                  whileInView='visible'
                  viewport={{ amount: 0.2, once: true }} //delay after scroll
               >
                  {mobileLayout ? (
                     <motion.p variants={animationFunc(0, 150, 0.3, 1.7)}>
                        Kula is about sharing. Sharing invites, tickets and +1s,
                        with people in your area and with similar interests.
                        <br /> <span>why?</span> <br />
                        Because it's about time.
                        <br />
                        <br />
                        <span>let's share</span>
                     </motion.p>
                  ) : (
                     <>
                        <motion.p variants={animationFunc(0, 50, 1.0, 0.7)}>
                           Kula is about sharing. Sharing invites, tickets and
                           +1s, with people in your area and with
                        </motion.p>
                        <motion.p variants={animationFunc(0, 50, 1.0, 4.0)}>
                           similar interests.
                        </motion.p>
                        <motion.p variants={animationFunc(0, 50, 1.0, 6.0)}>
                           <span>why?</span>
                        </motion.p>
                        <motion.p variants={animationFunc(0, 50, 1.0, 8.0)}>
                           Because it's about time.
                        </motion.p>
                     </>
                  )}
               </motion.div>
               <motion.div
                  className='first-page__central-button'
                  initial='hidden'
                  whileInView='visible'
                  viewport={{ amount: 0.2, once: true }} //delay after scroll
               >
                  <motion.div
                     className='sign-up-button-container'

                     // variants={animationFunc(90, -300, 0.6, 0.1)}
                  >
                     <SignUpButton textButton={'let’s share'} />
                  </motion.div>
                  {/* <motion.button
                     className='sign-up-button'
                     onClick={() => setCurrentButton(6)}
                     variants={animationFunc(290, -900, 0.6, 0.1)}
                  >
                     <Link to={'scroll-id' + 6} offset={-120} smooth={true}>
                        SIGN UP
                     </Link>
                  </motion.button> */}
               </motion.div>
            </div>
         </div>
      </section>
   );
};

export default FirstPartMainPage;
