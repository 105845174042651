import React from 'react';

import './mvp-first-part.scss';
import BlobThree from '../../../components/blobs/blob-three';
import SignUpButton from '../../../components/buttons/signUpButton/signUpButton';
import BlobOne from '../../../components/blobs/blob-one';
import BlobTwo from '../../../components/blobs/blob-two';
const MvpFirstPart = () => {
   const blobAnimation = (duration, delay) => {
      const blobAnimation = {
         hidden: {
            width: 0
         },
         visible: {
            width: '100%',
            transition: { duration: duration, delay: 0.2 * delay }
         }
      };
      return blobAnimation;
   };
   return (
      <section
         id='scroll-id5'
         className='mcp-first-page w-auto h-auto flex flex-col  m-auto'
      >
         <div className='mvp-first-page__first-part flex w-full  relative justify-between mt-[-1.5vw] max-lg:mt-[4vw]'>
            <h2 className='mvp-first-page__first-text flex my-auto ml-[12.5%] max-lg:ml-[19%] '>
               Kula is a <br />
               safe space <br /> for sharing...
            </h2>
            <div className='mvp-first-page__blob-one'>
               <BlobOne blobAnimation={blobAnimation} />
            </div>
            <div className='mvp-first-page__blob-two'>
               <BlobTwo blobAnimation={blobAnimation} />
            </div>

            <div className='mr-[14%]'>
               <img
                  className='mvp-first-page__first-image max-md:[scale-50]'
                  src='image/kula-mvp/first-page-first-img.svg'
                  alt=''
               ></img>
            </div>
         </div>
         <div className='flex justify-center mt-[50px] max-md:mt-[80px]'>
            {' '}
            <div className='mvp-first-page_blob-container mr-[200px]  max-md:ml-[50px]'>
               <BlobThree blobAnimation={blobAnimation}></BlobThree>
            </div>
            <h2 className='mvp-first-page__second-text'>
               invites, tickets and +1s.
            </h2>
         </div>

         <div className='mvp-first-part__buttons flex gap-5 m-auto'>
            <SignUpButton
               setCurrentButton={6}
               textButton={'i have a ticket'}
               windowLink={
                  'https://join.slack.com/t/kulaplusone/shared_invite/zt-21i6a1rd1-m67XL_bFjHEwhSURIuEqGQ'
               }
            />
            <SignUpButton
               setCurrentButton={6}
               textButton={'i’m looking for one'}
               windowLink={
                  'https://join.slack.com/t/kulaplusone/shared_invite/zt-21i6a1rd1-m67XL_bFjHEwhSURIuEqGQ'
               }
            />
         </div>
      </section>
   );
};

export default MvpFirstPart;
