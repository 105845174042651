import React from 'react';

import './mvp-four-part.scss';
import RoadMapMainPage from '../../../components/roadMapMainPage/roadMapMainPage';

const MvpFourPart = () => {
   return (
      <section id='scroll-id2'>
         {' '}
         <div className=' mvp-roadmap'>
            {' '}
            <RoadMapMainPage></RoadMapMainPage>
         </div>
      </section>
   );
};

export default MvpFourPart;
