import React from 'react';
import { scrollIntoView} from 'seamless-scroll-polyfill';
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Header from '../../components/header/header';
import FirstPartMainPage from '../../components/firstPartMainPage/firstPartMainPage';
import SecondPartMainPage from '../../components/secontPartMainPage/secondPartMainPage';
import ThirdPartMainPage from '../../components/thirdPartMainPage/thirdPartMainPage';
import SignUpPage from '../../components/SignUpPage/signUpPage';
import AboutMainPage from '../../components/aboutMainPage/aboutMainPage';
import RoadMapMainPage from '../../components/roadMapMainPage/roadMapMainPage';
import KulaSoKulMainPage from '../../components/kulaSoKulMainPage/kulaSoKulMainPage';

import LineAnimation from '../../components/roadMapMainPage/lineAnimation/lineAnimation';

import '../../index.css';



const MainPage = () => {
   const divWhatsThatRef = React.useRef();
   const divHowItWorksRef = React.useRef();
   const divWhatAboutRef = React.useRef();
   const divWhereToRef = React.useRef();
   const divWhoRef = React.useRef();
   const divHomeRef = React.useRef();

   const divSignUpRef = React.useRef();




   const handleScrollUsers = (index) => {


      const executeScroll = () => divHowItWorksRef.current.scroll()    
      console.log('handleScrollUsers index',index)
      
      switch (index) {
         case 0:
            
            // executeScroll()
            // scrollIntoView(divWhatsThatRef.current, {
            //    behavior: 'smooth',
            //    alignToTop: 'true'
            // });
            break;
         case 1:
            // <Link  to='ididid' spy={true} smooth={true}></Link>
           
            // scroll.scrollTo(400)
            // scrollIntoView(divHowItWorksRef.current, { behavior: 'smooth' });
            break;
         case 2:
            // <Link  to={'button-header'} spy={true} smooth={true}></Link>
            // scrollIntoView(divWhatAboutRef.current, { behavior: 'smooth' });
            break;
         case 3:
            // scrollIntoView(divWhereToRef.current, { behavior: 'smooth' });
            break;
         case 4:
            // scrollIntoView(divWhoRef.current, { behavior: 'smooth' });

            break;
         case 5:
   
            scroll.scrollToTop();
           
            break;
         case 6:
       
            // scrollIntoView(divSignUpRef.current, { behavior: 'smooth' });
            break;
         default:
            break;
      }
   };

   return (
      <div>
         <Header handleScrollUsers={handleScrollUsers} />
         <div ref={divHomeRef}>
            <FirstPartMainPage handleScrollUsers={handleScrollUsers} />
         </div>

         <div ref={divWhatsThatRef} id='ididid'>
            <SecondPartMainPage />
         </div>
         <div ref={divHowItWorksRef}>
            <ThirdPartMainPage />
         </div>
         <div ref={divSignUpRef}>
            <SignUpPage />
         </div>
         <div ref={divWhatAboutRef}>
            <AboutMainPage />
         </div>
         <div ref={divWhereToRef}>
            <RoadMapMainPage />
         </div>
         <div ref={divWhoRef}>
            <KulaSoKulMainPage />
         </div>
         {/* <LineAnimation/> */}
      </div>
   );
};

export default MainPage;
