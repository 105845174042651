import React from 'react';
import { motion } from 'framer-motion';

import BottomDevPart from '../../pages/devPage/bottomDevPart';

import './kulaSoKulMainPage.scss';

const KulaSoKulMainPage = () => {
   const kulaKulText = [
      'People get invited to events they would never have thought to attend.',
      'If your friend gets sick, or bails out, why waste the ticket, when you can share it.',
      'It helps to learn the city and find kul friends.',
      'New way of networking between people with mutual interests and love of sharing.'
   ];

   const animationFunc = (x, y, duration, delay) => {
      const textAnimation = {
         hidden: {
            x: x,
            y: y,
            opacity: 0
         },
         visible: (custom) => ({
            x: 0,
            y: 0,
            opacity: 1,
            transition: { duration: 0.3, delay: 0.2 * delay } //delay
         })
      };
      return textAnimation;
   };

   return (
      <div className='container-main-page'>
         <div id='scroll-id4' className='kula-kul__three-things'>
            {/* <p>what makes Kula so kul?</p> */}
            {/* <img src='/image/soKul.png' alt='img-three' /> */}
         </div>
         <motion.div
            initial='hidden'
            whileInView='visible'
            viewport={{ amount: 0.2, once: true }} //delay after scroll
            className='kula-kul__title'
         >
            <motion.p custom={2}
                        variants={animationFunc(0, 50, 1.0, 0.5)}>three things that make Kula so kul</motion.p>
         </motion.div>

         <div className='kula-kul__flex-container'>
            {kulaKulText.map((item, index) => {
               return (
                  <motion.div
                     initial='hidden'
                     whileInView='visible'
                     viewport={{ amount: 0.2, once: true }} //delay after scroll
                     key={index}
                     className='kula-kul__flex-container-cell'
                  >
                     <motion.div
                        custom={2}
                        variants={animationFunc(0, 50, 1.0, 0.5 + index)}
                        className='kula-kul__flex-container-cell-number'
                     >
                        {' '}
                        0{index + 1}
                     </motion.div>
                     <motion.div
                        custom={2}
                        variants={animationFunc(0, 50, 1.0, 1.5 + index)}
                        className='kula-kul__flex-container-cell-text'
                     >
                        {item}
                     </motion.div>
                  </motion.div>
               );
            })}
         </div>
         <motion.div
            initial='hidden'
            whileInView='visible'
            viewport={{ amount: 0.2, once: true }} //delay after scroll
            className='kula-kul__bottom-title'
         >
            <motion.p custom={2} variants={animationFunc(0, 50, 1.0, 2.5)}>
               it's 4. but whatever. be kul.
            </motion.p>
         </motion.div>

         <div className='kula-kul__bottom-final-part-container'>
            <BottomDevPart />
         </div>
      </div>
   );
};

export default KulaSoKulMainPage;
