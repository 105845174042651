import React, { useState, useEffect } from 'react';
import Countdown, {
   zeroPad,
   calcTimeDelta,
   formatTimeDelta,
   zeroPadTime
} from 'react-countdown';
import { Link } from 'react-scroll';

import SignUpButton from '../buttons/signUpButton/signUpButton';

import './header.scss';

const Header = ({ handleScrollUsers }) => {
   //   /header-right-side-disable

   const buttons = [
      "what's that?",
      'how it works?',
      'what about? ',
      'where to?',
      'who?'
   ];

   const [currentButton, setCurrentButton] = useState(666);

   const Completionist = () => <span>You are good to go!</span>;

   const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
         // Render a completed state
         return <Completionist />;
      } else {
         // Render a countdown
         return (
            <span>
               <p>{zeroPad(days)}DAYS</p>
               <p>{zeroPad(hours)}H</p>
               <p>{zeroPad(minutes)}M</p>
               <p>{zeroPad(seconds)}S</p>
            </span>
         );
      }
   };

   useEffect(() => {
      handleScrollUsers(currentButton);
      setCurrentButton(666);
   }, [currentButton]);

   return (
      <div className='header-wrapper'>
         <div className='header'>
            <div className='header-all'>
               <div className='header-left-side'>
                  <img
                     src='/image/kula-logo.svg'
                     onClick={() => setCurrentButton(5)}
                     alt=''
                  />

                  <div className='center-header'>
                     <div className='center-buttonz'>
                        {buttons.map((buttonz, index) => {
                           return (
                              <button
                                 key={index}
                                 onClick={() => setCurrentButton(index)}
                              >
                                 {' '}
                                 <Link
                                    to={'scroll-id' + index}
                                    offset={-120}
                                    smooth={true}
                                 >
                                    {buttonz}
                                 </Link>
                              </button>
                           );
                        })}
                     </div>
                  </div>
               </div>

               <div className='header-right-side'>
                  {console.log(window.innerWidth)}
                  <SignUpButton setCurrentButton={setCurrentButton} textButton={'join us'} />
                  {/* <button className= 'sign-up-button' onClick={() => setCurrentButton(6)}>     <Link  to={'scroll-id'+6} offset={-120} smooth={true}>SIGN UP</Link></button> */}
                  <div className='right-label-counter'>
                     <div className='next-label'>NEXT SHARE IN:</div>
                     <div className='time-left'>
                        {
                           <Countdown
                              date={new Date('March 22, 2023 12:24:00')}
                              renderer={renderer}
                              zeroPadTime={2}
                              zeroPadDays={2}
                           />
                        }
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Header;
