import React from 'react';
import './mvp-second-part.scss';
const MvpSecondPart = () => {
   const data = [
      {
         number: '01',
         label: 'share or find',
         descr: 'On Kula you can do two things:',
         li: [
            '  share your invites, tickets or 1+s',
            'find people who already did '
         ]
      },
      {
         number: '02',
         label: 'connect',
         descr: 'When you find the right event or person, get in touch, talk through details, and... ',
         li: []
      },
      {
         number: '03',
         label: 'go. go. go.',
         descr: 'Network. Share experience. Have fun.',
         li: []
      }
   ];
   return (
      <section id='scroll-id0' className='mvp-second-part flex flex-col'>
         <div></div>
         <div className='mvp-second-part__main-text pt-[100px]'>
            how it works?
         </div>
         <div className='flex gap-10 mt-[50px] mx-[10%] grid-cols-1 justify-center items-start max-md:flex-col max-md:items-center max-md:mt-[10px] max-md:gap-2'>
            {data.map((item, index) => (
               <div
                  key={index}
                  className='mvp-second-part__flex-text-container flex flex-col height-[500px] items-center max-w-xs gap-2'
               >
                  <h2 className='mvp-second-part-number p-2'>{item.number}</h2>
                  <h3 className='mvp-second-part-label mt-[5%]'>
                     {item.label}
                  </h3>
                  <p className='mvp-second-part-descr mt-[5%]'>{item.descr}</p>
                  <h3 className=' pl-6'>
                     {item.li.map((li, i) => (
                        <p className='mvp-second-part_li' key={i}>
                           {li}
                        </p>
                     ))}
                  </h3>
               </div>
            ))}
         </div>

         <div className='table'></div>
      </section>
   );
};

export default MvpSecondPart;
