import React from 'react';

import './mvp-third-part.scss';
import WeAreWhatWeShare from '../../../components/weAreWhatWeShare/we-are-what-we-share';
import MainImage from '../../../components/mainImage/mainImage';
import SignUpButton from '../../../components/buttons/signUpButton/signUpButton';
const MvpThirdPart = () => {
   const things = [
      { num: '01', descr: ' discover previously unknown events' },
      { num: '02', descr: ' network in a meaningful way ' },
      { num: '03', descr: ' breath new life to spare tickets ' },
      { num: '04', descr: ' explore local communities  ' }
   ];
   return (
      <section className='mvp-third-part flex flex-col'>
         <div className='mvp-third-part__main-text pt-[50px]'>
            why are we doing this?
         </div>
         <p className='mvp-third-part-descr mt-[2%]'>
            We’re connecting people, who are looking for new ways of enjoy{' '}
            <br />
            activities in their cities. Be it a house party, a professional{' '}
            <br />
            conference, or a gig, in Kula you can find the right person to share{' '}
            <br />
            this experience with you.{' '}
         </p>
         <div className='mvp-third-part__join-button mt-[30px]'>
            <SignUpButton
               setCurrentButton={6}
               textButton={'join our community'}
               windowLink={
                  'https://join.slack.com/t/kulaplusone/shared_invite/zt-21i6a1rd1-m67XL_bFjHEwhSURIuEqGQ'
               }
            />
         </div>
         <div className='mvp-third__what-weshare mt-[-150px] max-md:mt-[-100px]'>
            <WeAreWhatWeShare />
         </div>

         <h2
            id='scroll-id1'
            className='mvp-third-part__three-things  mt-[-200px]'
         >
            three things that make <br /> Kula so kul
         </h2>
         <div className='w-full flex justify-center items-center max-sm:flex-col'>
            <div className=' flex flex-col items-right justify-right ml-[120px] max-md:ml-[50px] max-md:ml-[0px] max-sm:w-[90%] max-md:w-[40%]'>
               <ul className='mvp-third-part__things flex flex-col items-left gap-5 justify-center mb-5'>
                  {things.map((thing, i) => (
                     <li
                        key={i}
                        className='mvp-third-part__things-item flex justify-left float-left items-left'
                     >
                        <p>{thing.num}</p> {thing.descr}
                     </li>
                  ))}
               </ul>
               <div className='mvp-third-part__things-bottom-text flex float-left text-left'>
                  it's 4. but whatever.
                  <br /> be kul.
               </div>
            </div>
            <div className=' flex items-right justify-right  '>
               {' '}
               <div className='mvp-third-part__main-image  max-w-[500px]'>
                  <div className='mvp-third-part__main-image-wrapper mt-[100px] '>
                     {' '}
                     <MainImage />
                  </div>
               </div>
            </div>
         </div>
         <h2 className='mvp-third-part__three-things mvp-third-part__roadmap-title'>
            Kula’s roadmap
         </h2>
      </section>
   );
};

export default MvpThirdPart;
