import React, { useState, useEffect } from 'react';

import { ArrowRightBlue } from '../../images/arrowRightBlue'

import './customForm.css'

const CustomForm = ({ status, message, onValidated, setDisableInput }) => {
   const [email, setEmail] = useState('');



   const handleFormSubmit = (e) => {
      e.preventDefault();

      onValidated({
         EMAIL: email,
         MERGE1: 'firstname',
         MERGE2: 'lastname'
      });
    }

      React.useEffect(() => {
       if (status === 'success') {setDisableInput(true)}
      }, [status])

      //   await axios
      //      .post('/posts', [
      //         ...responseEmails,
      //         {
      //            id: 1,
      //            email: email
      //         }
      //      ])
      //      .then((response) => response.json())
      //      .then((json) => setResponseEmails(json))
      //      .catch(function (error) {
      //         console.log(error);
      //         alert('error added email', error);
      //      });
      // setMailSend(true);

      // setEmail('');


   return (
      <form id='form' onSubmit={(e) => handleFormSubmit(e)}   className='form-input-button-border'>
         <input 
       
            placeholder='Email'
            type='email'
           
            onChange={(data) =>
               setEmail(data.target.value)
            }
            value={email}
         ></input>{' '}
         <button onClick={handleFormSubmit}>
            {/* <img src='/image/Input-image.png' alt='' type='submit' /> */}
            <ArrowRightBlue/>
         </button>{' '}
         {status === 'sending' && (
            <div style={{ color: 'white' }}>sending...</div>
         )}
         {status === 'error' && (
            <div
               style={{ color: 'red' }}
               dangerouslySetInnerHTML={{
                  __html: message
               }}
            />
         )}
         {status === 'success' && (
            <div>You're in! Kul updates koming soon</div>
         )}
      </form>
   );
};

export default CustomForm;
