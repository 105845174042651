import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import SignUpButton from '../../../components/buttons/signUpButton/signUpButton';

import './mvp-header.scss';

const MvpHeader = () => {
   //   /header-right-side-disable

   const buttons = ['how it works?', 'why so kul?', 'where are we?'];

   const [currentButton, setCurrentButton] = useState(666);

   useEffect(() => {
      // handleScrollUsers(currentButton);
      setCurrentButton(666);
   }, [currentButton]);

   return (
      <div className='header-wrapper mvp-header-wrapper flex items-center justify-center m-auto'>
         <div className='header  mvp-header'>
            <div className='header-all'>
               <div className='header-left-side'>
                  <img
                     src='/image/kula-logo.svg'
                     onClick={() => setCurrentButton(5)}
                     alt=''
                  />
                  <h2 className='absolute bottom-[6px] left-[28.5px] max-md:left-[54px] max-md:bottom-[-2px]'>
                     warsaw
                  </h2>

                  <div className='center-header mvp-center-header'>
                     <div className=' center-buttonz'>
                        {buttons.map((buttonz, index) => {
                           return (
                              <button
                                 key={index}
                                 onClick={() => setCurrentButton(index)}
                              >
                                 {' '}
                                 <Link
                                    to={'scroll-id' + index}
                                    offset={Number(
                                       (index === 1 && -110) ||
                                          (index === 2 && -230)
                                    )}
                                    smooth={true}
                                 >
                                    {buttonz}
                                 </Link>
                              </button>
                           );
                        })}
                     </div>
                  </div>
               </div>

               <div className='mvp-header-right-side'>
                  <SignUpButton
                     setCurrentButton={setCurrentButton}
                     textButton={'join kula'}
                     windowLink={
                        'https://join.slack.com/t/kulaplusone/shared_invite/zt-21i6a1rd1-m67XL_bFjHEwhSURIuEqGQ'
                     }
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default MvpHeader;
