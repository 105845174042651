import React from 'react';
import { motion } from 'framer-motion';


import './blob-one.scss';

const BlobOne = ({blobAnimation}) => {


   return (
      <div className='blob-container'>
         <div className='blob-one'>
            <motion.svg
               width='600px'
               viewBox='0 0 800 500'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'
               variants={blobAnimation(0.5, 1)}
               className='first-page__yellow-image'
            >
               <defs>
                  <linearGradient
                     id='gradient'
                     x1='0%'
                     y1='0%'
                     x2='0%'
                     y2='100%'
                  >
                     <stop
                        offset='0%'
                        style={{stopColor: 
                        'rgba(255, 180, 10, 1)'}}
                     ></stop>
                     <stop
                        offset='100%'
                        style={{stopColor: 
                            'rgba(255, 180, 10, 1)'}}
                     ></stop>
                  </linearGradient>
               </defs>
               <g transform='translate(170.8523712158203, -34.94581604003906)'>
                  <path fill='url(#gradient)'>
                     <animate
                        attributeName='d'
                        dur='18000ms'
                        repeatCount='indefinite'
                        values='M444.5,314.5Q404,379,337,372Q270,365,198,415Q126,465,135.5,376.5Q145,288,133.5,246Q122,204,157,176.5Q192,149,234,126.5Q276,104,358.5,97Q441,90,463,170Q485,250,444.5,314.5Z;M375.5,285.5Q335,321,309,378.5Q283,436,213,435.5Q143,435,154,358Q165,281,144,242.5Q123,204,141.5,148.5Q160,93,216.5,106Q273,119,317.5,137.5Q362,156,389,203Q416,250,375.5,285.5Z;M410.5,282Q326,314,304,372.5Q282,431,224.5,413Q167,395,101.5,361.5Q36,328,58,258Q80,188,116.5,134.5Q153,81,217,76.5Q281,72,327.5,109Q374,146,434.5,198Q495,250,410.5,282Z;M406.5,283Q328,316,301,350.5Q274,385,209.5,408.5Q145,432,160,354.5Q175,277,146,239Q117,201,158,181Q199,161,245,90.5Q291,20,314,98.5Q337,177,411,213.5Q485,250,406.5,283Z;M444.5,314.5Q404,379,337,372Q270,365,198,415Q126,465,135.5,376.5Q145,288,133.5,246Q122,204,157,176.5Q192,149,234,126.5Q276,104,358.5,97Q441,90,463,170Q485,250,444.5,314.5Z;'
                     ></animate>
                  </path>
               </g>
            </motion.svg>
         </div>
       
      </div>
   );
};

export default BlobOne;
