import React from 'react';
import { motion } from 'framer-motion';

import './aboutMainPage.scss';

const AboutMainPage = () => {
   const aboutTitleText = [
      {
         title: 'who are them kulers?',
         text: 'they are just like strangers, except strangers are strange and Kulers are Kul. people here might be strangers at first, but everyone is one share away from each other.',
         open: false
      },
      {
         title: 'the art of sharing',
         text: 'sharing has also been part of human behavior for centuries. it’s what ancient societies were built on, and what Kula stands for. if You have a spare ticket or a +1, You’re going for a yoga lesson or throwing a party, why not tag Kul people along?',
         open: false
      },
      {
         title: 'new way of networking',
         text: 'We’re tired of meaninglessness. Kula’s about meeting people with similar interests and values, spending quality time together and finding new friends and useful connections.',
         open: false
      },
      {
         title: 'sense of community',
         text: 'We are a community of people who like to share and spend time with purpose and Kul people. We want to talk to each other, and trust each other. We’re building a social media that stands for Ukraine and the right values. Come share them with us.',
         open: false
      }
   ];
   const [openText, setOpenText] = React.useState(aboutTitleText);

   //animation
   const animationFunc = (x, y, duration, delay) => {
      const textAnimation = {
         hidden: {
            x: x,
            y: y,
            opacity: 0
         },
         visible: (custom) => ({
            x: 0,
            y: 0,
            opacity: 1,
            transition: { duration: duration, delay: 0.2 * delay } //delay
         })
      };
      return textAnimation;
   };

   const toggleAbout = (item, index) => {
      const toggleItem = () =>
         setOpenText([
            ...openText.slice(0, index),
            {
               title: item.title,
               text: item.text,
               open: !item.open
            },
            ...openText.slice(index + 1)
         ]);

      if (item.open) {
         setTimeout(() => {
            toggleItem();
         }, 0);
      } else {
         toggleItem();
      }
   };

   return (
      <div className='container-main-page'>
         <motion.div  initial='hidden'
              whileInView='visible'
              viewport={{ amount: 0.2, once: true }} //delay after scroll
               id='scroll-id2' className='about__about-title'>
            <motion.h2  custom={2}
                        variants={animationFunc(0, 50, 1.0, 0.5)} >about Kula</motion.h2>
         </motion.div>

         <div className='about__list-container'>
            <ul>
               {openText.map((item, index) => {
                  return (
                     <li
                        key={index}
                        onClick={() => toggleAbout(item, index)}
                        className={item.open ? 'li-open' : 'li-close'}
                     >
                        <div className='list-container__title-button'>
                           <div className='title-button__title'>
                              <p>{item.title}</p>
                           </div>
                           <button className='title-button__button'>
                              {!item.open ? (
                                 <div className='title-button_container'>
                                    <div className='title-button_open-button'>
                                       {/* <svg
                                       width='30'
                                       height='30'
                                       viewBox='0 0 40 40'
                                       fill='none'
                                       xmlns='http://www.w3.org/2000/svg'
                                    >
                                       <path
                                          d='M20 6.66675V33.3334'
                                          stroke='#1E56E1'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                       />
                                       <path
                                          d='M33.3334 20L6.66671 20'
                                          stroke='#1E56E1'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                       />
                                    </svg> */}
                                    </div>
                                    <div className='title-button_open-button_dop'></div>
                                 </div>
                              ) : (
                                 <div className='title-button_container'>
                                    <div className='title-button_close-button'>
                                       {/* <svg
                                       width='20'
                                       height='20'
                                       viewBox='0 0 30 2'
                                       fill='none'
                                       xmlns='http://www.w3.org/2000/svg'
                                    >
                                       <path
                                          d='M28.3334 1L1.66671 0.999999'
                                          stroke='#1E56E1'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                       />
                                    </svg> */}
                                    </div>
                                    <div className='title-button_open-button_dop'></div>
                                 </div>
                              )}
                           </button>
                        </div>
                        {!item.open ? (
                           <div className='list-container__text'>
                              <p className='list-container__text'></p>
                           </div>
                        ) : (
                           <div className='list-container__text-active'>
                              <p className='list-container__text-active'>
                                 {item.text}
                              </p>
                           </div>
                        )}
                     </li>
                  );
               })}
            </ul>
         </div>
      </div>
   );
};

export default AboutMainPage;
