import React, { Fragment, useEffect, useState } from 'react';
import {
   BrowserRouter as Router,
   Routes,
   Route
} from 'react-router-dom';
import ReactGA from 'react-ga';

import DevPage from './pages/devPage/devPage';
import MainPage from './pages/mainPage/mainPage';

import './App.scss';
import './fonts/HelveticaNeueCyr/stylesheet.css';
import './fonts/NeueMachina/stylesheet.css';
import MvpPage from './mvp-page/mvp-page';

//GOOGLE
function App() {
   const TRACKING_ID = 'G-9D8X6QQJYT'; // OUR_TRACKING_ID
   ReactGA.initialize(TRACKING_ID);

   React.useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
   }, []);

   //SPINNER LOADER
   const [isLoading, setLoading] = useState(true);
   function someRequest() {
      //Simulates a request; makes a "promise" that'll run for 2.5 seconds
      return new Promise((resolve) => setTimeout(() => resolve(), 2500));
   }
   useEffect(() => {
      someRequest().then(() => {
         const loaderElement = document.querySelector('#loader');

         if (loaderElement) {
            loaderElement.remove();

            setLoading(!isLoading);
         }
      });
   });
   if (isLoading) {
      return null;
   }
   return (
      <div className='App font-face-gm'>
         <Router>
            <Fragment>
               <Routes>
                  <Route exact path='/mvp' element={<DevPage />}></Route>
                  <Route exact path='/mainpage' element={<MainPage />}></Route>
                  {/* <Route exact path='/emails' element={<Emails/>}/> */}
                  <Route exact path='/*' element={<MvpPage />}></Route>
                  {/* <Route exact path='/devtest' element={<DevTest/>}/> */}
                  {/* <Route exact path='/setnewtime' element={<SetNewTime/>}></Route> */}
                  {/* <Route path="/*" element={<DefaultRoute/>}></Route> */}
               </Routes>
            </Fragment>
         </Router>
      </div>
   );
}

export default App;
