import React from 'react';
import {
   motion,
   useViewportScroll,
   useTransform,
   useScroll,
   useSpring
} from 'framer-motion';

import RoadMapInCss from './roadMapImgInCss/roadMapInCss'
import RoadMapImage from '../roadMapImage/roadmapImage';

import './roadMapMainPage.scss';

// import MainImgRoadMap from './mainImgRoadMap'

const RoadMapMainPage = () => {
   //    const { scrollYProgress } = useViewportScroll();
   //    const scale = useTransform(scrollYProgress, [1, 1], [0.2, 1]);

   const animationFunc = (x, y, duration, delay) => {
      const textAnimation = {
         hidden: {
            x: x,
            y: y,
            opacity: 0
         },
         visible: (custom) => ({
            x: 0,
            y: 0,
            opacity: 1,
            transition: { duration: 0.3, delay: 0.2 * delay } //delay
         })
      };
      return textAnimation;
   };

   const ref = React.useRef(null);
   const { scrollYProgress } = useScroll({
      target: ref,
      offset: ['10vh', '70vh']
   });

   const scaleX = useSpring(scrollYProgress, {
      stiffness: 100,
      damping: 30,
      restDelta: 0.001
   });

   const icon = {
      hidden: {
         // opacity: 0,
         pathLength: 0,
         fill: 'rgba(255, 255, 255, 0)'
      },
      visible: {
         // opacity: 1,
         pathLength: 1,
         fill: 'rgba(255, 255, 255, 1)'
      }
   };
   return (
      <motion.section
         ref={ref}
         //  initial='hidden'
         //  whileInView='visible'
         //  viewport={{ amount: 0.2, once: false }} //delay after scroll
      >
         <div id='scroll-id3' className='container-main-page'>
            <motion.div  initial='hidden'
            whileInView='visible'
            viewport={{ amount: 0.2, once: true }} //delay after scroll
             className='road-map__title'>
               <motion.p custom={2}
                        variants={animationFunc(0, 50, 1.0, 0.5)}>Kula's roadmap</motion.p>
            </motion.div>

            <motion.div className='container-road-map'>
               <motion.div className='road-map__main-img'>
                  {/* <RoadMapImage /> */}
                  
               <RoadMapInCss/>
               </motion.div>
            </motion.div>
         </div>
      </motion.section>
   );
};

export default RoadMapMainPage;
