import React from 'react';

import './info-dop-icon.scss';

const InfoDopIcon = ({ infoText }) => {
   return (
      <div className='info-icon-container'>
         <h4>{infoText}</h4>
         <div className='info-icon-arrow-main'>
           

            {/* <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.1066 3.03516L13.0711 13.0707L3.03552 3.03516H23.1066Z" fill="black" stroke="#557DE0" strokeWidth="2"/>
<path d="M24.6067 0.607422L13.0711 12.1431L1.5354 0.607422H24.6067Z" fill="black"/>
</svg> */}

         </div>
      </div>
   );
};

export default InfoDopIcon;
