import { motion } from 'framer-motion';

import './animationRectangle.scss'

const AnimationRectangle = () => {

    //Text animation from left side
    const animationFunc = (x, y, duration, delay) => {
      const textAnimation = {
         hidden: {
            x: x,
            y: y,
            opacity: 0
         },
         visible: (custom) => ({
            x: 0,
            y: 0,
            opacity: 1,
            transition: { duration: duration, delay: 0.2 * delay, repeat: Infinity } //delay
         })
        
      };
      return textAnimation;
   };


   return (
      <motion.div className='anim-rect'
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.2, once: false, loop: true }} //delay after scroll
      >

<motion.div
        custom={2}
          variants={animationFunc(-1200, -1200, 2.5, 5)}
        className='animation-rectangle'
       >
      </motion.div>
      {/* <motion.svg
        custom={2}
        variants={animationFunc(-1200, -1200, 2.5, 0)}
         width='554'
         height='338'
         viewBox='0 0 554 338'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
      >
         <rect
            x='365.838'
            y='-337.982'
            width='261.602'
            height='687.584'
            transform='rotate(44.2057 365.838 -337.982)'
            fill='url(#paint0_linear_815_104)'
         />
         <defs>
            <linearGradient
               id='paint0_linear_815_104'
               x1='620.244'
               y1='50.7413'
               x2='377.499'
               y2='64.9407'
               gradientUnits='userSpaceOnUse'
            >
               <stop offset='0.234375' />
               <stop offset='1' stopOpacity='0' />
            </linearGradient>
         </defs>
      </motion.svg> */}
      </motion.div>
   );
};

export default AnimationRectangle;
