export const ArrowRightBlue = () => {
   return (
      <svg
         width='24'
         height='17'
         viewBox='0 0 24 17'
         fill='none'
         xmlns='http://www.w3.org/2000/svg'
      >
         <path
            d='M15.7975 1L23.2841 8.48657M23.2841 8.48657L15.7975 15.9731M23.2841 8.48657L0.656738 8.48665'
            stroke='#1E56E1'
         />
      </svg>
   );
};
