import './mvp-page.scss';
import './mvp-global.css';
import MvpHeader from './components/header/mvp-header.component';
import MvpFirstPart from './components/first-part/mvp-first-part.component';
import MvpSecondPart from './components/second-part/mvp-second-part.component';
import MvpThirdPart from './components/third-part/mvp-third-part';
import MvpFourPart from './components/four-part/mvp-four-part';
import React from 'react';
import MvpFivePart from './five-part/mvp-five-part';
const MvpPage = () => {
   const divMvpOne = React.useRef();
   const divMvpTwo = React.useRef();
   const divMvpThree = React.useRef();
   const divMvpFour = React.useRef();
   return (
      <div className='root-mvp'>
         <MvpHeader />
         <MvpFirstPart />
         <div ref={divMvpOne}>
            <MvpSecondPart />
         </div>
         <div ref={divMvpTwo}>
            <MvpThirdPart />
         </div>
         <div ref={divMvpThree}>
            <MvpFourPart />
         </div>
         <div ref={divMvpFour}>
            <MvpFivePart />
         </div>
      
      </div>
   );
};

export default MvpPage;
