import React from 'react';
import { motion } from 'framer-motion';

import './blob-two.scss';

const BlobTwo = ({ blobAnimation }) => {
   return (
    <div className='blob-container'>
    <div className='blob-two'>
    <motion.svg
               width='100%'
               viewBox='0 0 800 500'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'
               variants={blobAnimation(0.5, 2.0)}
               className='first-page__yellow-image'
            >
               <defs>
                  <linearGradient
                     id='gradienttwo'
                     x1='0%'
                     y1='0%'
                     x2='0%'
                     y2='100%'
                  >
                     <stop
                        offset='0%'
                        style={{stopColor: 
                        'rgba(255, 94, 39, 1)'}}
                     ></stop>
                     <stop
                        offset='100%'
                        style={{stopColor: 
                            'rgba(255, 94, 39, 1)'}}
                     ></stop>
                  </linearGradient>
               </defs>
               <g transform='translate(170.8523712158203, -34.94581604003906)'>
                  <path fill='url(#gradienttwo)'>
                     <animate
                        attributeName='d'
                        dur='18000ms'
                        repeatCount='indefinite'
                        values='M348.5,297Q250,344,198.5,297Q147,250,198.5,205.5Q250,161,348.5,205.5Q447,250,348.5,297Z;M322.5,309Q250,368,202.5,309Q155,250,202.5,194Q250,138,322.5,194Q395,250,322.5,309Z;M356,288Q250,326,201,288Q152,250,201,149.5Q250,49,356,149.5Q462,250,356,288Z;M296.5,333Q250,416,141.5,333Q33,250,141.5,209Q250,168,296.5,209Q343,250,296.5,333Z;M298,323.5Q250,397,176,323.5Q102,250,176,180Q250,110,298,180Q346,250,298,323.5Z;M348.5,297Q250,344,198.5,297Q147,250,198.5,205.5Q250,161,348.5,205.5Q447,250,348.5,297Z;'
                     ></animate>
                  </path>
               </g>
            </motion.svg>
      </div>
       
       </div>
   );
};

export default BlobTwo;
