import React, { useRef, useEffect, useState } from 'react';
import { motion, useScroll } from 'framer-motion';
import Lottie from 'lottie-react';

import './we-are-what-we-share.scss';

import animation from './wearewhatweshareReverse.json';

const WeAreWhatWeShare = () => {
  

   const options = {
      animationData: animation,
      loop: false
   };

   const interactivity = {
      mode: 'scroll',
      actions: [
         {
            visibility: [0.2, 0.45],
            type: 'seek',
            frames: [-20, 100]
         }
      ]
   };
   return (
      <div className='container-wearewhat'>

      <div className='test-anim'>
         <div className='test-anim__main-container'>
            {' '}
            <Lottie
               animationData={animation}
               interactivity={interactivity}
               loop={false}
            />
         </div>
      </div>
      </div>
   );
};

export default WeAreWhatWeShare;
