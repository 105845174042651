import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';


import MainImage from '../../components/mainImage/mainImage';
import BottomDevPart from './bottomDevPart'

import './devPage.scss';

function DevPage() {
   
   const [bottomPartLoad, setBottomPartLoad] = useState(false)

   useEffect(() => {
      setTimeout(() => {
         setBottomPartLoad(true)
      }, 1000);
   }, [bottomPartLoad])

   const textAnimation = {
      hidden: {
         x: -100,
         opacity: 0
      },
      visible: (custom) => ({
         x: 0,
         opacity: 1,
         transition: { delay: custom * 0.2 } //delay
      })
   };

   const textAnimationReverse = {
      hidden: {
         x: 100,
         opacity: 0
      },
      visible: (custom) => ({
         x: 0,
         opacity: 1,
         transition: { delay: custom * 0.2 } //delay
      })
   };


   return (
      <>
         <motion.section
            initial='hidden'
            whileInView='visible'
            viewport={{ amount: 0.2, once: false }} //delay after scroll
            className='container'
         >
            <div className='logo-section w-full'>
               <div className='logo '>
                  <img className='flex m-auto' src='/image/kula-logo.svg' width={244} alt='' />
               </div>
               <div>
               <motion.p
                  custom={2}
                  variants={textAnimation}
                  className='text-main'
               >
                  new platform for sharing events 
               </motion.p>
               <motion.p
                  custom={3}
                  variants={textAnimationReverse}
                  className='text-main'
               >
                  and networking
               </motion.p>
               </div>
               <motion.p
                  custom={2}
                  variants={textAnimation}
                  className='text-main-mobile'
               >
                  new platform
               </motion.p>
               <motion.p
                  className='text-main-mobile'
                  custom={3}
                  variants={textAnimationReverse}
               >
                  for
               </motion.p>
               <motion.p
                  className='text-main-mobile'
                  custom={4}
                  variants={textAnimation}
               >
                  sharing events
               </motion.p>
               <motion.p
                  className='text-main-mobile'
                  custom={5}
                  variants={textAnimationReverse}
               >
                  and networking
               </motion.p>
            </div>
            <MainImage />
            {/* <motion.img
                  className='main-img-main'
                     custom={4}
                     variants={imgAnimation}
                     // className='main-img-main'
                     // src='/image/Group 48095413.png'
                     src='/image/main-img.png'
                     alt=''
                  />
                  <motion.img
                     custom={7}
                     variants={textAnimation}
                     className='main-img-dop'
                     // src='/image/Group 48095413.png'
                     src='/image/main-img-dop.png'
                     alt=''
                  /> */}

            <motion.div
               initial='hidden'
               whileInView='visible'
               viewport={{ amount: 0.2, once: false }} //delay after scroll
            >
               <motion.p
                  custom={1}
                  variants={textAnimation}
                  className='text-second'
               >
                  koming soon
               </motion.p>
            </motion.div>

            <motion.div 
               initial='hidden'
               whileInView='visible'
               viewport={{ amount: 0.2, once: true, delay: 1 }} //delay after scroll
            >
                 <motion.div
                  custom={2}
                
                
               >
                  {bottomPartLoad &&  <BottomDevPart/>}
                
               </motion.div>

            </motion.div>
          
          
         </motion.section>
      </>
   );
}

export default DevPage;
