import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import CustomForm from './customForm';

const BottomDevPart = () => {
   const [disableInput, setDisableInput] = React.useState(false);

   const postUrl = `https://kulaplus.us12.list-manage.com/subscribe/post?u=c6496d48a58fcb2a4e1552057&id=859bb6828e`;

   return (
      <div className='bottom-part_container'>
         <div className='bottom-part'>
            <div className='left'>
               <div className='left-side'>
                  <img
                     className='left-side-img-left'
                     src='/image/We are what We share_2.svg'
                     alt=''
                  />
                  {/* <h1>VV</h1> */}
                  <img
                     className='left-side-img-right'
                     src='/image/We are what We share.svg'
                     alt=''
                  />
                  {/* <p>e are what</p>
                  <p>e share</p> */}
               </div>
               <div className='socials-main'>
                  <div className='socials'>
                     <a
                        href='https://www.facebook.com/Kulaplusone-102162512588882'
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        Facebook
                     </a>{' '}
                     |{' '}
                     <a
                        href='https://www.instagram.com/kulaplus.one/'
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        Instagram
                     </a>{' '}
                     |{' '}
                     <a
                        href='https://www.linkedin.com/company/86923235/'
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        LinkedIn
                     </a>
                  </div>
                  <div className='copywrite'><p>©2023 Kula</p></div>
               </div>
            </div>

            <div className='right-side'>
               {disableInput ? (
                  ''
               ) : (
                  <p className='right-side-text'>
                     to keep up with all our kul updates and what we're up to,
                     leave your email here:
                  </p>
               )}

               {!disableInput ? (
                  <div>
                     <MailchimpSubscribe
                        url={postUrl}
                        render={({ subscribe, status, message }) => (
                           <CustomForm
                              status={status}
                              message={message}
                              onValidated={(formData) => subscribe(formData)}
                              setDisableInput={setDisableInput}
                           />
                        )}
                     />
                  </div>
               ) : (
                  <span className='registered-mail'>
                     U'r in! Kul updates koming soon
                  </span>
               )}

               <div className='privacy-policy'>
                  <p>Privacy Policy</p> <p>Terms of Service</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BottomDevPart;
