import React from 'react';
import $ from 'jquery';
import { Link } from 'react-scroll';

import './signUpButton.scss';

const SignUpButton = ({ setCurrentButton, textButton, windowLink }) => {
   /* eslint-disable no-mixed-spaces-and-tabs */
   /* eslint-disable no-undef */

   setTimeout(() => {
      $('.button_su_inner').on('mouseenter', function (e) {
         var parentOffset = $(this).offset();

         var relX = e.pageX - parentOffset.left;
         var relY = e.pageY - parentOffset.top;
         $(this).prev('.su_button_circle').css({ left: relX, top: relY });
         $(this).prev('.su_button_circle').removeClass('desplode-circle');
         $(this).prev('.su_button_circle').addClass('explode-circle');
      });

      $('.button_su_inner').on('mouseleave', function (e) {
         var parentOffset = $(this).offset();

         var relX = e.pageX - parentOffset.left;
         var relY = e.pageY - parentOffset.top;
         $(this).prev('.su_button_circle').css({ left: relX, top: relY });
         $(this).prev('.su_button_circle').removeClass('explode-circle');
         $(this).prev('.su_button_circle').addClass('desplode-circle');
      });
   }, 500);

   return (
      <div className='button_container'>
         <div className='button_su'>
            <span className='su_button_circle'></span>
            <button
               className='button_su_inner'
               // onClick={() => {
               //    setCurrentButton(6);
               // }}
            >
               {windowLink ? (
                  <span
                     onClick={() => {
                        window.open(windowLink);
                     }}
                     className='button_text_container'
                  >
                     {textButton}
                  </span>
               ) : (
                  <Link to={'scroll-id' + 6} offset={-120} smooth={true}>
                     {' '}
                     <span className='button_text_container'>{textButton}</span>
                  </Link>
               )}
            </button>
         </div>
      </div>
   );
};

export default SignUpButton;
