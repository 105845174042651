import React from 'react';
import { motion } from 'framer-motion';
import { useLottie } from "lottie-react";
import Lottie from "lottie-react";
import animation from './main-img-animation.json'

import './mainImage.css';

const MainImage = () => {
   

   const options = {
    animationData: animation,
    loop: false

  };

  const interactivity = {
   mode: "scroll",
   actions: [

     {
       visibility: [0.2, 0.55],
       type: "seek",
       frames: [-29, 100],
     },

   ],

 };
  const { View } = useLottie(options);

   return (
      <div className='main-img'>
<Lottie animationData={animation} interactivity={interactivity} loop={false} />
        <div className='main-image__full '>
         <div className='main-image__right'>
           
         </div>
        </div>
      </div>
   );
};

export default MainImage;
