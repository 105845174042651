import React from 'react';
import { motion } from 'framer-motion';

import './blob-three.scss';

const BlobThree = ({blobAnimation}) => {
   return (
    <div className='blob-container'>
    <div className='blob-three'>
    <motion.svg
               width='100%'
               viewBox='0 0 800 500'
               fill='none'
               xmlns='http://www.w3.org/2000/svg'
               variants={blobAnimation(0.5, 4.0)}
               className='first-page__left-image'
            >
               <defs>
                  <linearGradient
                     id='gradientthree'
                     x1='0%'
                     y1='0%'
                     x2='0%'
                     y2='100%'
                  >
                     <stop
                        offset='0%'
                        style={{stopColor: 
                        '#1e56e1'}}
                     ></stop>
                     <stop
                        offset='100%'
                        style={{stopColor: 
                            '#1e56e1'}}
                     ></stop>
                  </linearGradient>
               </defs>
               <g transform='translate(170.8523712158203, -34.94581604003906)'>
                  <path fill='url(#gradientthree)'>
                     <animate
                        attributeName='d'
                        dur='18000ms'
                        repeatCount='indefinite'
                        values='M348.5,361Q322,472,190,431Q58,390,73.5,261.5Q89,133,203.5,86.5Q318,40,346.5,145Q375,250,348.5,361Z;M348.5,307.5Q287,365,199,358Q111,351,123.5,259Q136,167,214,144Q292,121,351,185.5Q410,250,348.5,307.5Z;M348.5,331.5Q303,413,183,399.5Q63,386,113.5,287Q164,188,225,164Q286,140,340,195Q394,250,348.5,331.5Z;M383,359Q321,468,186,431.5Q51,395,97.5,284Q144,173,234,97.5Q324,22,384.5,136Q445,250,383,359Z;M383,330.5Q302,411,216.5,373.5Q131,336,91,221Q51,106,177,96Q303,86,383.5,168Q464,250,383,330.5Z;M346.5,365.5Q325,481,226,410.5Q127,340,136.5,257Q146,174,226,125Q306,76,337,163Q368,250,346.5,365.5Z;M348.5,361Q322,472,190,431Q58,390,73.5,261.5Q89,133,203.5,86.5Q318,40,346.5,145Q375,250,348.5,361Z;'
                     ></animate>
                  </path>
               </g>
            </motion.svg>
      </div>
       
       </div>
   );
};

export default BlobThree;
