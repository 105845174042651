import React from 'react';
import $ from 'jquery';


import './confirmSignUpButton.scss';
import '../../SignUpPage/signUpPage.scss'

const ConfirmSignUpButton = ({
   loadingFormCompleteMsg,
   backendError,
   children
}) => {
   /* eslint-disable no-mixed-spaces-and-tabs */
   /* eslint-disable no-undef */

   setTimeout(() => {
      $('.button_su_inner_confirm').on('mouseenter', function (e) {
         var parentOffset = $(this).offset();

         var relX = e.pageX - parentOffset.left;
         var relY = e.pageY - parentOffset.top;
         $(this).prev('.su_button_circle_confirm').css({ left: relX, top: relY });
         $(this).prev('.su_button_circle_confirm').removeClass('desplode-circle');
         $(this).prev('.su_button_circle_confirm').addClass('explode-circle');
      });

      $('.button_su_inner_confirm').on('mouseleave', function (e) {
         var parentOffset = $(this).offset();

         var relX = e.pageX - parentOffset.left;
         var relY = e.pageY - parentOffset.top;
         $(this).prev('.su_button_circle_confirm').css({ left: relX, top: relY });
         $(this).prev('.su_button_circle_confirm').removeClass('explode-circle');
         $(this).prev('.su_button_circle_confirm').addClass('desplode-circle');
      });
   }, 500);

   return (
      <div className='button_container_confirm'>
         <div className='button_su_confirm'>
            <span className='su_button_circle_confirm'></span>
            <button
               className={
                'button_su_inner_confirm'
               }
               type='submit'
               disabled={loadingFormCompleteMsg || backendError ? true : false}
            >
               <div  className={
                  loadingFormCompleteMsg || backendError
                     ? 'inputs-container__signup-button signup-button-disabled'
                     : 'inputs-container__signup-button signup-button-enable'
               }>
               <div className='inputs-container__signup-button-text'>
                 

              {children}
                  </div>
               </div>
            </button>
         </div>
      </div>
   );
};

export default ConfirmSignUpButton;
